'use client';

import { useState } from 'react';
import {
  CancellationsIcon,
  ComplaintsIcon,
  CustomersCallingIcon,
  IconCheckmark,
  OrderIssuesIcon,
  section7,
} from '../../../assets';
import {
  AnimatedDiv,
  AnimatedH2,
  AnimatedH4,
  AnimatedP,
} from '../../../components';
import { t } from '../../../helpers';
import { Div, Icon, Img, P, WrapperCenterSimpler } from '../../../styles';
import { HoverCheckText, HoverIcon, RequestDemoButton } from '../home.styled';
import Image from 'next/image';

const icons = [
  {
    value: 1,
    top: '-15%',
    left: '50%',
    icon: CancellationsIcon,
    text: 'Cancellations',
  },
  {
    value: 2,
    top: '95%',
    left: '25%',
    icon: OrderIssuesIcon,
    text: 'Order issues',
  },
  {
    value: 3,
    top: '23%',
    left: '-10%',
    icon: CustomersCallingIcon,
    text: 'Customer communication',
  },
  {
    value: 4,
    top: '55%',
    left: '80%',
    icon: ComplaintsIcon,
    text: 'Complaints',
  },
];

export const Section7 = () => {
  const [hover, setHover] = useState(null);

  return (
    <WrapperCenterSimpler reverse>
      <Div $width={'50%'} $widthSM={'100%'}>
        <AnimatedDiv $flexSM={t} $centerSM={t} $relative={t}>
          <Image
            src={section7}
            alt="concierge"
            width={0}
            height={0}
            sizes="100vw"
            style={{ width: '100%', height: 'auto' }}
          />
          {icons.map((icon, i) => (
            <HoverIcon
              animate={{
                scale: [1, 1.2, 1],
              }}
              transition={{
                duration: 2,
                ease: 'easeIn',
                repeat: Infinity,
                delay: i * 0.5,
              }}
              key={i}
              onMouseEnter={() => setHover(icon.value)}
              onMouseLeave={() => setHover(null)}
              top={icon.top}
              left={icon.left}
            >
              <icon.icon />
            </HoverIcon>
          ))}
        </AnimatedDiv>

        <AnimatedDiv $mtSM="50" $mobile>
          <RequestDemoButton />
        </AnimatedDiv>
      </Div>
      <Div $width={'50%'} $widthSM={'100%'}>
        <AnimatedH4>concierge</AnimatedH4>
        <AnimatedH2>
          No more hassle. <br />
          <span> Our team will handle it.</span>
        </AnimatedH2>

        <AnimatedP>
          Don’t have time to handle the extra little headaches? Our team is
          trained to recover lost revenue by calling delivery companies when
          there are cancellations, and much more. It’s as if you have a whole
          office working for you, but from the cloud. We’ll take care of:
        </AnimatedP>

        {icons.map((icon, i) => (
          <AnimatedDiv key={i}>
            <HoverCheckText hovered={hover === icon.value}>
              <Icon>
                <IconCheckmark />
              </Icon>
              <P>{icon.text}</P>
            </HoverCheckText>
          </AnimatedDiv>
        ))}

        <AnimatedDiv $mt="40" $mtSM="20" $desktop>
          <RequestDemoButton />
        </AnimatedDiv>
      </Div>
    </WrapperCenterSimpler>
  );
};
