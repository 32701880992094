'use client';

import { motion } from 'framer-motion';
import Link from 'next/link';
import styled from 'styled-components';
import { Button } from '../../components';
import { SCHEDULE_A_CALL } from '../../helpers/constants';

export const RequestDemoButton = () => {
  return (
    <Link href={SCHEDULE_A_CALL}>
      <Button>
        Schedule Call <span className="screen-reader-text">Schedule Call</span>
      </Button>
    </Link>
  );
};

export const HoverIcon = styled(motion.div)<{
  top: string | number;
  left: string | number;
}>`
  position: absolute;
  top: ${({ top }) => top};
  left: ${({ left }) => left};
  transition: transform 0.2s ease;

  :hover {
    transform: scale(1.1);
  }

  @media (min-width: 3500px) {
    svg {
      transform: scale(2);
    }
  }

  @media (max-width: 1000px) and (orientation: landscape) {
    svg {
      transform: scale(0.6);
    }
  }

  @media (max-width: 800px) {
    svg {
      transform: scale(0.6);
    }
  }
`;

export const HoverCheckText = styled.div<{ hovered: boolean }>`
  p {
    margin: 0 !important;
    transition: all 0.4s ease;
  }
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;
  gap: 15px;
  height: 40px;
  padding: 0 15px;
  padding-right: 25px;
  overflow: hidden;
  border-radius: 50px;
  transition: all 0.4s ease;

  ::before {
    position: absolute;
    content: '';
    top: 0;
    bottom: 0;
    z-index: -1;
    left: 0;
    right: 0;
    transition: all 0.4s ease;
    transform: translateX(-110%);
    background: ${({ theme }) => theme.primary};
    border-radius: 100px;
  }

  @media (min-width: 3500px) {
    padding: 0 30px;
    margin-bottom: 10px;
    height: 80px;
    gap: 30px;

    ::before {
      border-radius: 200px;
    }
  }

  ${({ hovered }) =>
    hovered &&
    `
  gap: 10px;

  @media (min-width: 3500px) {
  gap: 20px;

  }

 :before {
    transform: translateX(0);
  };
  p {
    color:#fff
  }
    `}
`;
