import(/* webpackMode: "eager" */ "/app/apps/vgrubs/app/home/home.styled.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/app/home/sections/section7.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/growthCalculator/growthCalculator.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/iframeWrapper/iframeWrapper.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/components/index.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/chatWidget.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/gtm.tsx");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/helpers/helpers.js");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/styles.ts");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/text.ts");
import(/* webpackMode: "eager" */ "/app/apps/vgrubs/styles/theme.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js")